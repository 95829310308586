<template>
  <div>
    <SiteSelect v-model="form.site" :sitesFilter="sitesFilter" />
  </div>
</template>

<script>
import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';
import { useStockpilingRequirementsStore } from '@/stores/StockpilingRequirements';

import SiteSelect from '@/site_components/SiteSelect/index.vue';

export default {
  name: "Site",
  components: {
      SiteSelect,
  },
  created() {
    this.store = useRecordStockpilingStore();
    this.stockpilingRequirementsStore = useStockpilingRequirementsStore();
  },
  computed: {
    form() {
      return this.store.stockpiling;
    },
  },
  methods: {
    sitesFilter(sites) {
      const stockpilingSites = this.stockpilingRequirementsStore.stockpilingRequirements.map(requirement => requirement.site);
      if (stockpilingSites.length === 0) return sites;
      return sites.filter(site => stockpilingSites.includes(site.id));
    },
  }
}

</script>