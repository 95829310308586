<template>
  <div>
    <form v-show="!store.submitted" @submit.prevent="submit">
      <FormHeader />
      <Divider />
      <CompletionTime />
      <Divider />
      <Site />
      <Divider />
      <div v-if="hasStockpilingRequirements">
        <TarpSpecifics />
        <Divider />
        <HarvestPredictions />
        <Divider />
        <SiteStatus />
        <Divider />
      </div>
      <Tarps />
      <Divider />
      <div v-if="hasStockpilingRequirements">
        <SummaryOfInputs />
        <Divider />
      </div>
      <div class="field p-fluid">
        <Button label="Submit" severity="secondary" size="large" :disabled="!store.inputsValid" type="submit" raised />
      </div>
    </form>
    <FormSubmitLoader 
      v-if="store.submitted"
      :loading="store.loading"
      :status="responseStatus"
      :errors="responseErrors"
      @recordAnother="recordAnother"
      @returnToForm="returnToForm"
      @reSubmitForm="reSubmit"
    />
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';
import { useStockpilingRequirementsStore } from '@/stores/StockpilingRequirements';

import FormHeader from '../../components/FormHeader.vue';
import HarvestPredictions from './components/HarvestPredictions.vue';
import SiteStatus from './components/SiteStatus.vue';
import SummaryOfInputs from './components/SummaryOfInputs.vue';
import TarpSpecifics from './components/TarpSpecifics.vue';
import CompletionTime from './fields/CompletionTime.vue';
import Site from './fields/Site.vue';
import Tarps from './fields/Tarps.vue';

import FormSubmitLoader from '@/site_components/FormSubmitLoader/index.vue';

import Button from 'primevue/button';
import Divider from 'primevue/divider';

export default {
  name: "StockpilingForm",
  components: {
    FormHeader,
    FormSubmitLoader,
    CompletionTime,
    Site,
    SiteStatus,
    SummaryOfInputs,
    Tarps,
    HarvestPredictions,
    TarpSpecifics,
    Button,
    Divider,
  },
  created() {
    this.store = useRecordStockpilingStore();
    this.store.init();

    this.stockpilingRequirementsStore = useStockpilingRequirementsStore();
  },
  computed: {
    responseStatus() {
      return this.store.saveResponse?.status;
    },
    responseErrors() {
      return this.store.getErrors;
    },
    hasStockpilingRequirements() {
      return this.stockpilingRequirementsStore.stockpilingRequirements.filter(
        (req) => req.site === this.store.stockpiling.site
      ).length > 0;
    },
  },
  methods: {
    async submit() {
      this.store.loading = true;
      this.store.submitted = true;
      try {
        await this.store.saveStockpiling();
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      } finally {
        // Store a local copy of the form that we can use to restore the form
        // if the user wants to modify the data. Otherwise, we need to reset the
        // store to clear the form.
        this.localData = this.store.stockpiling;
        // Reset the store
        this.store.reset(false);
        this.store.loading = false;
      }
    },

    recordAnother() {
      this.store.reset(true);
    },
    reSubmit() {
      this.store.stockpiling = this.localData;
      this.submit();
    },
    returnToForm() {
      this.store.stockpiling = this.localData;
      this.store.submitted = false;
    },
  }
}

</script>