<template>
  <div>
    <ul class="tarpingWork-attr-list">
      <li v-for="field in fields" :key="field.label">
        <span class="attr">
          {{ field.label }}
        </span>
        <span class="value">
          {{ field.value }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TarpingWorkDetails',
  inject: ['dialogRef'],
  created() {
    this.tarpingWorks = this.dialogRef.data.tarpingWorks
  },
  data () { return {
    tarpingWorks: null,
  }},
  computed: {
    fields() {
      const tw = this.tarpingWorks;
      const fields = [
        { label: 'Completion Time', value: tw.completionTimeDisplay() },
        { label: 'Supervisor', value: tw.supervisorDisplay() },
      ]

      if (tw.is_new_stack === true) {
        fields.push({ label: 'Is New Stack', value: 'Yes' });
        fields.push({ label: 'Has Carryover Grain', value: (tw.has_carryover_grain) ? 'Yes' : 'No' });
        
        if (tw.has_carryover_grain === true) {
          fields.push({ label: 'Starting PA', value: tw.starting_peak_actual });
          fields.push({ label: 'Pulled Back Folded Tarp Length', value: tw.pulled_back_folded_tarp_length });
        }
        if (tw.filling_end) fields.push({ label: 'Filling End', value: tw.filling_end });
      }

      fields.push({ label: 'Activities', value: tw.activitiesDisplay() });

      if (tw.cover_estimated || tw.cover_estimated_max === true) fields.push({ label: 'Cover Estimated', value: (tw.cover_estimated_max) ? 'Max' : tw.cover_estimated });
      if (tw.peak_actual) fields.push({ label: 'Peak Actual', value: tw.peak_actual });
      if (tw.toe_location) fields.push({ label: 'Toe Location', value: tw.toe_location });
      if (tw.cover_actual || tw.cover_actual_max === true) fields.push({ label: 'Cover Actual', value: (tw.cover_actual_max) ? 'Max' : tw.cover_actual });
      if (tw.wind_speed) fields.push({ label: 'Wind Speed', value: tw.wind_speed });
      if (tw.tarps.length > 0) fields.push({ label: 'Tarps', value: tw.tarpsDisplay() });
      if (tw.permed || tw.permed_max) fields.push({ label: 'Permed', value: (tw.permed_max) ? 'Max' : tw.permed });
      if (tw.seams_wozzied || tw.seams_wozzied_max) fields.push({ label: 'Seams Wozzied', value: (tw.seams_wozzied_max) ? 'Max' : tw.seams_wozzied });
      if (tw.comments) fields.push({ label: 'Comments', value: tw.comments });
      if (tw.stack_closed_out ===true)  fields.push({ label: 'Stack Closed Out', value: 'Yes' });

      return fields
    }
  }
}
</script>

<style scoped>
.tarpingWork-attr-list {
  list-style: none;
  padding: 0;
  margin-left: -6px;
  margin-right: -6px;
}
.tarpingWork-attr-list li {
  display: flex;
  justify-content: space-between;
  padding: 6px;
}
.tarpingWork-attr-list li:nth-child(odd) {
  background-color: #f2f2f2;
}
.tarpingWork-attr-list li .attr {
  font-weight: bold;
  width: 155px;
  flex-shrink: 0;
}
.tarpingWork-attr-list li .value {
  flex-grow: 1;
  text-align: left;
}
</style>
