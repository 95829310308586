export const setThemeColor = (themeColor) => {
    const metaTheme = document.querySelector('meta[name="theme-color"]');
    if (metaTheme) {
        metaTheme.setAttribute('content', themeColor);
    }
};


export function isTenant(tenant) {
    const host = window.location.hostname;
    return host.includes(tenant);
}

export function isLocalhost() {
    const host = window.location.hostname;
    return host.includes('localhost');
}

export function isIASTenant() {
    return isTenant('localhost') || isTenant('ias');
}
