<template>
  <div>
    <div class="field p-fluid">
      <label for="tarps">{{ label }}</label>
      <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
      <div class="flex justify-center p-fluid py-2">
        <Button label="Add Tarps" severity="info" @click="openSelectTarpsModal" />
      </div>
    </div>
    <div>
      <h3>Selected Tarps</h3>
      <div class="selected-tarps-list">
        <slot name="selectedTarps">
          <TarpListItem v-for="(tarp, idx) in tarps"
            :key="tarp.client_id" 
            :tarp="tarp"
            :showLocation="false">
            <template #button>
              <Button severity="danger" size="small" text @click="removeTarp(tarp)">
                <template #icon>
                  <span class="material-symbols-outlined">delete</span>
                </template>
              </Button>
            </template>
          </TarpListItem>
        </slot>
        <div class="text-center mt-2" v-if="tarps.length === 0">
          <p class="text-gray-500">No tarps selected.</p>
        </div>
      </div>
    </div>

    <SelectTarpsModal 
      :visible="selectTarpsModalVisible"
      :multiple="true"
      ordering="serial_no"
      :selectedTarps="tarps"
      :baseTarps="baseTarps"
      @onSelect="confirmSelectTarps"
      @onAddNew="openNewTarpModal"
      @close="selectTarpsModalVisible = false"
    />

    <NewTarpModal
      ref="newTarpModal"
      :visible="newTarpModalVisible"
      :formOptions="{
        serialNumberType: serialNumberType,
        generateSerialNumber: generateSerialNumber,
      }"
      @onCreate="confirmCreateNewTarp"
      @close="closeNewTarpModal"
    />

  </div>
</template>

<script>
import SelectTarpsMenu from '@/site_components/SelectTarpsMenu/index.vue';
import TarpListItem from '@/site_components/SelectTarpsMenu/TarpListItem.vue';
import NewTarpForm from '@/site_components/NewTarpForm/index.vue';

import SelectTarpsModal from '@/site_components/SelectTarpsModal/index.vue';
import NewTarpModal from '@/site_components/NewTarpModal/index.vue';

import Button from 'primevue/button';

export default {
  name: "TarpsSelectField",
  components: {
    SelectTarpsMenu,
    TarpListItem,
    NewTarpForm,
    SelectTarpsModal,
    NewTarpModal,
    Button,
  },
  props: {
    modelValue: {
      // Array of Tarp objects
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    serialNumberType: {
      type: String,
      default: 'single',
      options: ['single', 'double'],
    },
    generateSerialNumber: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Tarps',
    },
    subtitle: {
      type: String,
      required: false,
    },
    baseTarps: {
      type: Boolean,
      default: false,
    },
    tarpDisabled: {
      type: Function,
      default: (tarp) => false,
    }
  },
  provide() { return {
    tarpDisabled: this.tarpDisabled,
  }},
  data() { return {
    createTarpLoading: false,
    newTarpModalVisible: false,
    selectTarpsModalVisible: false,

    // Store selected tarps when a user elects to create new tarp
    selectedTarps: [],
  }},
  computed: {
    tarps() {
      return this.modelValue?.sort((a, b) => a.serial_no - b.serial_no) || [];
    },
  },
  methods: {

    filterDuplicateTarps(tarps) {
      return tarps.filter((t, i, self) => self.findIndex(t2 => t2.client_id === t.client_id) === i);
    },

    openSelectTarpsModal() {
      this.selectTarpsModalVisible = true;
    },
    confirmSelectTarps(tarps) {
      // Triggered by SelectTarpsModal
      if (!tarps || tarps.length === 0) return;

      const newTarpsList = [ ...this.tarps, ...tarps ]
      // Filter out duplicates
      const uniqueTarps = this.filterDuplicateTarps(newTarpsList);
      this.update(uniqueTarps);

      this.selectTarpsModalVisible = false;
    },

    openNewTarpModal({ selectedTarps, searchSerialNo }) {
      this.selectedTarps = selectedTarps;
      this.newTarpModalVisible = true;

      // If a serial number was provided, set it in the form
      this.$nextTick(() => {
        if (searchSerialNo) this.$refs.newTarpModal.set({ serial_no: String(searchSerialNo) });
      });
    },
    closeNewTarpModal() {
      this.selectedTarps = null;
      this.newTarpModalVisible = false;
    },
    addNewTarp(selectedTarps) {
      // Open create tarp modal
      this.openNewTarpModal(selectedTarps);
    },
    confirmCreateNewTarp(newTarp) {
      // Triggered by NewTarpModal

      const newTarpsList = [ ...this.tarps, ...this.selectedTarps, newTarp ]
      // Filter out duplicates
      const uniqueTarps = this.filterDuplicateTarps(newTarpsList);
      this.update(uniqueTarps);

      // Close modals
      this.selectTarpsModalVisible = false;
      this.newTarpModalVisible = false;
    },

    removeTarp(tarp) {
      const newTarpsList = this.tarps.filter(t => t.client_id !== tarp.client_id);
      this.update(newTarpsList);
    },

    update(tarps) {
      this.$emit('update:modelValue', tarps);
    },

  }
}

</script>

<style scoped>
.selected-tarps-list {
  margin: 0.5rem -0.5rem;
}
</style>