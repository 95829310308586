<template>
  <div v-if="stockpiling.site">
    <Field label="Harvest Predictions">
      <div class="flex flex-column mt-3">
        <div class="row flex surface-overlay">
          <div class="cell font-bold text-center">Straight (m)</div>
          <div class="cell font-bold text-center bg-yellow">&gt;=20m</div>
          <div class="cell font-bold text-center bg-red">Single (&lt;12m)</div>
          <div class="cell font-bold text-center">End</div>
          <div class="cell font-bold text-center bg-dark-grey">E + S</div>
        </div>
        <div class="row flex surface-overlay">
          <div class="cell text-center">{{ stockpilingRequirements?.straight || 'N/A' }}</div>
          <div class="cell text-center">{{ stockpilingRequirements?.gte_20m_tarp_count || 'N/A' }}</div>
          <div class="cell text-center">{{ stockpilingRequirements?.single_tarp_count || 'N/A' }}</div>
          <div class="cell text-center">{{ stockpilingRequirements?.end_tarp_count || 'N/A' }}</div>
          <div class="cell text-center">{{ stockpilingRequirements?.end_straight_tarp_count || 'N/A' }}</div>
        </div>
      </div>
    </Field>
    <Divider />
    <Field label="Notes">
      <p v-if="stockpilingRequirements?.notes" v-html="stockpilingRequirements?.notes.replace(/\n/g, '<br>')"></p>
      <p v-else class="text-400">No notes provided.</p>
    </Field>
  </div>
</template>

<script>
import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';
import { useStockpilingRequirementsStore } from '@/stores/StockpilingRequirements';

import Divider from 'primevue/divider';
import Field from '@/components/forms/Field.vue';
import MaterialIcon from '@/components/icons/MaterialIcon.vue';

export default {
  name: 'HarvestPredictions',
  components: {
    Divider,
    Field,
    MaterialIcon,
  },
  created() {
    this.recordStore = useRecordStockpilingStore();
    this.store = useStockpilingRequirementsStore();
  },
  computed: {
    stockpiling() {
      return this.recordStore.stockpiling;
    },
    stockpilingRequirements() {
      return this.store.getStockpilingRequirementsBySiteID(this.stockpiling.site);
    },
  }
}

</script>

<style scoped>
.cell {
  padding: 10px 10px 6px;
  border: 1px solid black;
  width: 25%;
}
.cell:not(:first-child) {
  margin-left: -1px;
}
.row:not(:first-child) {
  margin-top: -1px;
}

.border-right-only {
  border: none;
  border-right: 1px solid black;

}
.border-left-only {
  border: none;
  border-left: 1px solid black;
}
  
</style>