<template>
  <div v-if="stockpiling.site">
    <Field label="Site Status">
      <table class="table mt-3">
        <thead>
          <tr>
            <th class="cell"></th>
            <th class="cell font-bold">Onsite</th>
            <th class="cell font-bold">Rem. Requirements</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item, idx in Object.entries(tarpAggregates)"  :key="item[0]">
            <td class="cell" :class="keyDisplayMap[item[0]].class">{{ keyDisplayMap[item[0]].display }}</td>
            <td class="cell text-center">{{ item[1] }}</td>
            <td class="cell text-center">
              {{ currentRequirements[item[0]] }}
            </td>
          </tr>
        </tbody>
      </table>
    </Field>
  </div>
</template>

<script>
import { useTarpInventoryStore } from '@/stores/TarpInventory';
import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';
import { useStockpilingRequirementsStore } from '@/stores/StockpilingRequirements';

import Field from '@/components/forms/Field.vue';
import MaterialIcon from '@/components/icons/MaterialIcon.vue';

export default {
  name: 'SiteStatus',
  components: {
    Field,
    MaterialIcon,
  },
  data() { return {
    keyDisplayMap: {
      straight: {
        display: 'Straight (m)',
        class: ''
      },
      gte_20m_tarp_count: {
        display: '>=20m',
        class: 'bg-yellow'
      },
      gte_16m_tarp_count: {
        display: '16-20m',
        class: 'bg-green'
      },
      gte_12m_tarp_count: {
        display: '12-16m',
        class: 'bg-blue'
      },
      single_tarp_count: {
        display: 'Single (<12m)',
        class: 'bg-red'
      },
      end_tarp_count: {
        display: 'End',
        class: ''
      },
      end_straight_tarp_count: {
        display: 'E + S',
        class: 'bg-dark-grey'
      },
    }
  }},
  created() {
    this.recordStore = useRecordStockpilingStore();
    this.store = useStockpilingRequirementsStore();
    this.tarpInventoryStore = useTarpInventoryStore();
  },
  computed: {
    stockpiling() {
      return this.recordStore.stockpiling;
    },
    stockpilingRequirements() {
      return this.store.getStockpilingRequirementsBySiteID(this.stockpiling.site);
    },
    onsiteTarps() {
      // Dont include tarps that are on stack
      return (this.stockpiling.site) ? 
        this.tarpInventoryStore.getTarpsBySite(this.stockpiling.site).filter(t => !t.stack) : [];
    },
    tarpAggregates() {
      const aggs = {};
      for (const key of Object.keys(this.keyDisplayMap)) {
        aggs[key] = 0;
      }

      for (const tarp of this.onsiteTarps) {
        const coverType = tarp.cover_type?.toLowerCase() || 'Unknown';

        if (coverType.includes('straight') && !coverType.includes('end')) {
          aggs.straight += tarp.length || 0;

          if (tarp.length >= 20) {
            aggs.gte_20m_tarp_count += 1;
          } else if (tarp.length >= 16) {
            aggs.gte_16m_tarp_count += 1;
          } else if (tarp.length >= 12) {
            aggs.gte_12m_tarp_count += 1;
          } else {
            aggs.single_tarp_count += 1;
          }
        } else if (coverType.includes('end') && !coverType.includes('straight')) {
          aggs.end_tarp_count += 1;
        } else if (coverType.includes('straight') && coverType.includes('end')) {
          aggs.end_straight_tarp_count += 1;
        }
      }
      
      // Round straight length to nearest whole number
      aggs.straight = Math.round(aggs.straight);

      return aggs;
    },

    currentRequirements() {
      const remainingRequirements = {}
      const stockpilingRequirements = this.stockpilingRequirements;
      const tarpAggregates = this.tarpAggregates;

      for (const [key, value] of Object.entries(tarpAggregates)) {
        if (stockpilingRequirements && stockpilingRequirements[key]) {
          remainingRequirements[key] = Math.max(stockpilingRequirements[key] - value, 0);
        } else {
          remainingRequirements[key] = 0;
        }
      }

      return remainingRequirements;
    }
  }
}

</script>

<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: scroll;
}
.cell {
  padding: 10px 10px 6px;
  border: 1px solid black;
}

</style>