<template>
  <div class="flex align-items-center p-2" :class="{'is-disabled': disabled}">
    <div class="flex-grow-1">
      <p class="font-semibold mb-0">{{ tarp.serial_no }}</p>
      <p><small>
        <span v-for="(val, index) in attrs" :key="index">
          {{ val }}<span v-if="index < attrs.length - 1"> &middot; </span>
        </span>
      </small></p>
    </div>
    <div class="flex-grow-0">
      <slot name="button">
        <Button 
          :label="selectBtnLabel" 
          size="small" 
          severity="info" 
          @click="selectTarp(tarp)"
          :outlined="isSelected" />
      </slot>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: "TarpListItem",
  props: ['tarp', 'multiple', 'isSelected', 'showLocation', 'disabled'],
  components: {
    Button,
  },
  computed: {
    selectBtnLabel() {
      if (this.isSelected) {
        return 'Selected';
      } else {
        return 'Select';
      }
    },
    attrs() {
      const attrs = [];
      if (this.showLocation !== false) attrs.push(this.tarp.site?.displayName() || 'Unknown site');
      if (this.tarp.stack) attrs.push(`${this.tarp.stack.storage.displayName()}/${this.tarp.stack.displayName()}`);
      if (this.tarp.length && this.tarp.width) {
        attrs.push(`${this.tarp.width}m x ${this.tarp.length}m`);
        attrs.push(this.tarp.coverTypeDisplayShort());
      }
      return attrs
    }
  },
  methods: {
    selectTarp() {
      this.$emit('selectTarp', this.tarp);
    }
  }
}
</script>

<style scoped>
.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>