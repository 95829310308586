import { v4 as uuidv4 } from 'uuid';

import TarpInventoryAPI from '@/stores/api/TarpInventory';

import { useRecordNewTarpStore } from '@/stores/RecordNewTarp';
import { useSitesStore } from '@/stores/Sites';
import { useStacksStore } from '@/stores/Stacks';
import Stack from './Stack';


class Tarp {

  id = null;
  client_id = null;
  condition = null;
  cover_type = null;
  leftmost_base_tarp = null;
  length = null;
  serial_no = null;
  site = null;
  is_maintained = null;
  stack = null;
  tarp_material = 'pvc';
  width = null;
  year_of_manufacture = null;
  join_type = null;

  api = null;

  sitesStore = null;
  stacksStore = null;

  activity_type = 'tarp';

  constructor() {
    this.client_id = uuidv4();

    this.api = new TarpInventoryAPI();

    this.sitesStore = useSitesStore();
    this.stacksStore = useStacksStore();
  }

  toJSON() {
    return {
      id: this.id,
      client_id: this.client_id,
      base_tarps: this.base_tarps,
      condition: this.condition,
      cover_type: this.cover_type,
      leftmost_base_tarp: this.leftmost_base_tarp,
      length: this.length,
      serial_no: this.serial_no,
      site: this.site,
      is_maintained: this.is_maintained,
      stack: this.stack?.toJSON() || null,
      tarp_material: this.tarp_material,
      width: this.width,
      year_of_manufacture: this.year_of_manufacture,
      join_type: this.join_type,
    }
  }

  fromJSON(data) {
    let site, stack;

    (data.site) ? site = this.sitesStore.getSiteById(data.site) : site = null;

    if (data.stack) {
      const stackId = data.stack.id;
      this.stack = this.stacksStore.getStackById(stackId);
      if (!this.stack) {
        // If the stack isn't found in the store, then it might be an old stack
        // from a previous season. In this case, we create a new stack object
        // and populate it with the data from the API.
        this.stack = new Stack().fromJSON(data.stack);
      }
    }

    this.id = data.id;
    this.client_id = data.client_id || data.id;
    this.base_tarps = data.base_tarps;
    this.condition = data.condition;
    this.cover_type = data.cover_type;
    this.leftmost_base_tarp = data.leftmost_base_tarp;
    this.length = data.length;
    this.serial_no = data.serial_no;
    this.site = site;
    this.is_maintained = data.is_maintained;
    this.tarp_material = data.tarp_material;
    this.width = data.width;
    this.year_of_manufacture = data.year_of_manufacture;
    this.join_type = data.join_type;

    return this
  }

  updateTarpInStores(response) {
    // Removes the tarping works from the locally saved tarping works and 
    // adds the updated tarping works to the tarping works store.
    const recordNewTarpStore = useRecordNewTarpStore();
    recordNewTarpStore.removeUnsavedTarpFromLocal(response.data.client_id);
    recordNewTarpStore.addToTarpInventoryStore(response.data);
    recordNewTarpStore.addToBaseTarpsStore(response.data.base_tarps);
  }

  syncToServer = async (data) => {
    if (!data) data = this.toJSON();

    const api = new TarpInventoryAPI();

    let response;

    try {

      if (this.id) response = await api.updateTarp(this.id, data);
      else         response = await api.createTarp(data);

      if ([200, 201].includes(response.status)) {
        this.updateTarpInStores(response);
      }

      return response;

    } catch (error) {

      Sentry.captureException(error);
      console.error(error);

      if (error.response) {
        if (error.response.status === 409) {
          // 409 is returned if the tarp has already been created on the server
          this.updateTarpInStores(error.response);
        }
        return error.response;
      } else {
        throw error;
      }
      
    }
  }

  generateSerialNo = async (count) => {
    const api = new TarpInventoryAPI();
    const response = await api.getGeneratedTarpSerialNumbers(count);
    return response
  }

  tableDisplay() {
    return `Tarp - ${this.serial_no}`
  }

  coverTypeDisplayShort() {
    // Split cover type into array
    const coverTypes = this.cover_type.split('/').map(ct => ct.toLowerCase());
    if (coverTypes.includes('straight') && coverTypes.includes('end')) {
      return 'E+S'
    } else if (coverTypes.includes('straight')) {
      return 'S'
    } else if (coverTypes.includes('end')) {
      return 'E'
    }
  }

  isValid() {
    if (this.serial_no && this.length && this.width && this.tarp_material && this.cover_type && this.condition && this.year_of_manufacture) {
      return true
    } else {
      return false
    }
  }
}

export default Tarp
